.message {
    &_body {
        width: fit-content;
        padding: 5px 15px 5px 5px;
    }
    &_time {
        font-size: 11px;
        margin-right: 5px;
    }
    &_username {
        font-weight: bold;
        &:after {
            content: ': ';
            color: black;
            font-weight: normal;
        }
    }
    &_message {
        word-break: break-all;
    }
}

.own {
    margin-left: auto;
    margin-right: 0;
}

.other {
    margin-left: 0;
    margin-right: auto;
}