.username-container {
    margin: 1em;
}

.games {
    margin: 1em;
}

.game {
    width: fit-content;
    cursor: pointer;
}