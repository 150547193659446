.room-number {
    font-size: large;
    text-align: center;
    padding: 0.5em;
}

.empty-chat-message {
    text-align: center;
}

.chat_messages-block {
    width: 100% !important;
    min-height: calc(100vh - 230px) !important;
    max-height: calc(100vh - 230px) !important;
    overflow: auto;
}

.chat_send-input {
    width: calc(100% - 3em);
}

.match-info-container {
    margin: 1em;
    padding: 1em;
    border-radius: 1.5em;
}


/*EMOJI*/

.emoji-mart-bar {
    display: none;
}

.emoji-mart {
    position: absolute;
    bottom: 15px;
    left: calc(360px) !important;
}

.emoji-trigger {
    text-align: center;
    display: inline-block;
    width: 40px;
    cursor: pointer;
    & span {
        font-size: 20px;
    }
}