.bar-chart {
    border-radius: 1em;
    display: flex;
    flex-direction: row;
}

.bar-data {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-text {
    text-align: center;
}