.chat_insight {
    margin: 5px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;

    .circle{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        background-color: white;
        border: 1px solid #bbb;
        border-radius: 50%;
    }

    .chat_insight-container-centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > p {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}
