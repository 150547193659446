.inner-table {
    margin: 0;
    text-align: center;
}

.filter {
    margin: 1em;
}

.stack-bar {
    width: auto;
    min-width: 25em;
}