.field {
    font-weight: bold;
}

.no-margin {
    margin: 0 !important;
}

.poll-container {
    border-radius: 20px;
    padding: 0 1em 1em 1em;
    color: black;
}

.question {
    margin-bottom: 10px;
}

.unvoted-option {
    padding: 0 2px 0 2px;
}

.voted-option {
    border-radius: 20px;
    margin: 10px !important;
    padding: 5px;
    padding-bottom: 23px;
    border: 1px solid grey;
}

.option-row {
    padding: 10px;
    border-radius: 20px;
}

.percentage {
    float: right;
}

.option {
    float: left;
}

.bet {
    margin-top: 40px;
    color: white;
    border-radius: 20px;
    padding: 15px;
    font-size: x-large;
}


/*MEDIA QUERIES*/

@media (max-width:800px) {
    .bet {
        font-size: large;
    }
}

@media (max-width:600px) {
    .bet {
        font-size: small;
    }
}