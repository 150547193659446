*,
*::before,
*::after {
    box-sizing: border-box;
}

.bordered {
    border: 1px solid black;
}

.radio-option {
    position: relative;
    height: 3em;
    background-color: white;
    border-radius: 1.5em;
}

.radio-option_content {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0.5em;
    top: 0.75em;
    bottom: 0.75em;
}

.poll-option {
    position: relative;
    height: 3em;
    background-color: white;
    border-radius: 1.5em;
    color: black;
    margin: 1em 0 0 0;
}

.poll-option::after {
    content: attr(data-label);
    position: absolute;
    display: flex;
    align-items: center;
    right: 1em;
    top: 0.5em;
    bottom: 0.5em;
}

.progress {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
    min-width: 2em;
    background-color: cyan;
    border-radius: 1.5em;
}

.success {
    background-color: greenyellow;
}

.option-text {
    position: absolute;
    display: flex;
    align-items: center;
    left: 1em;
    top: 0.5em;
    bottom: 0.5em;
}

.selected-option {
    margin-left: 0.5em;
    font-size: x-large;
}