.app_container__header {
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1em;
}

.header {
    position: relative;
    height: 4em;
    padding: 0.5em 0 0.5em;
    color: white;
}

.header_logo {
    height: 100%;
}

.header_title {
    font-size: x-large;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
}

.theme {
    position: absolute;
    top: 30%;
    right: 1em;
}

.game-title {
    height: 2em;
    color: white;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    padding-top: 0.6em;
}

.team {
    text-transform: uppercase;
    box-sizing: border-box;
}

.filter-container {
    padding: 1em;
}

.player {
    display: flex;
    margin: 0 auto 0 auto;
    width: auto;
    height: 92vh;
}

.item {
    width: 50%;
}

.tab {
    padding: 0 !important;
}

.back-btn {
    margin-left: auto;
    margin-right: 5px;
}

.stream-buttons {
    display: inline-block;
}


/*MEDIA QUERIES*/

@media (max-width:1580px) {
    .player {
        width: 100%;
        height: auto;
    }
}

@media (max-width:480px) {
    .game-title {
        font-size: x-large;
    }
}

@media (max-width:355px) {
    .game-title {
        font-size: large;
    }
}