.login-page_container {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;
    height: 600px;
    justify-content: center;
}

.exception {
    color: red;
}
