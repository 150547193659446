* {
    box-sizing: border-box;
    margin: 0;
}

.main-container {
    padding: 1em;
}

.container {
    box-sizing: border-box;
    margin: 5px 0px;
}

.insight-poll-container {
    background-color: whitesmoke;
    border-radius: 2em;
    margin: 0 0 1em 0;
}

.insight-container {
    position: relative;
    padding: 0 !important;
}