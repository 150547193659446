.insight {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 5em;
    height: auto;
    border-radius: 1.5em;
    padding: 1em;
    color: white;
}

.insight-text {
    display: inline-block;
    max-width: calc(100% - 4em);
}

.clear {
    clear: both;
}

.insight-icon {
    position: absolute;
    right: 0.5em;
    top: 20%;
}

.chart-icon-area {
    background-color: white;
    color: black;
}

.close-icon-area {
    background-color: red;
    color: white;
}

.no-margin {
    margin: 0 !important;
}


/*MEDIA QUERIES*/

@media (max-width:426px) {
    .insight-icon {
        top: 30%;
    }
}